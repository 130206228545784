:root {
    --colors-white-100: hsl(206, 5%, 100%);
    --colors-white-200: hsl(206, 5%, 90%);
    --colors-white-300: hsl(206, 5%, 80%);
    --colors-white-400: hsl(206, 5%, 65%);
    --colors-white-500: hsl(206, 5%, 50%);
    --colors-black-100: hsl(213, 20%, 10%);
    --colors-black-200: hsl(213, 23%, 8%);
    --colors-black-300: hsl(214, 21%, 6%);
    --colors-black-400: hsl(210, 21%, 6%);
    --colors-black-500: hsl(216, 22%, 4%);
    --colors-black-600: hsl(220, 18%, 3%);
    --colors-black-700: hsl(220, 27%, 2%);
    --colors-black-800: hsl(220, 30%, 1%);
    --colors-blue-100: hsl(220, 100%, 95%);
    --colors-blue-200: hsl(220, 100%, 85%);
    --colors-blue-300: hsl(220, 100%, 75%);
    --colors-blue-400: hsl(220, 100%, 65%);
    --colors-blue-500: hsl(220, 100%, 55%);
    --colors-blue-600: hsl(220, 100%, 45%);
    --colors-blue-700: hsl(220, 100%, 35%);
    --colors-blue-800: hsl(220, 100%, 25%);
    --colors-blue-900: hsl(220, 100%, 15%);
    --shadows-small: 0 1px 3px 0 rgba(0, 0, 50, 0.1), 0 1px 2px 0 rgba(0, 0, 50, 0.06);
    --shadows-medium: 0 4px 6px -1px rgba(0, 0, 50, 0.1), 0 2px 4px -1px rgba(0, 0, 50, 0.06);
    --shadows-large: 0 10px 15px -3px rgba(0, 0, 50, 0.1), 0 4px 6px -2px rgba(0, 0, 50, 0.05);
    --header-color: transparent;
    --header-text-color: #ffffff;
    --header-scroll-color: rgba(10, 15, 17, 0.7);
    --header-search-color: hsl(220, 100%, 55%);
    --header-search-text-color: hsl(220, 94%, 49%);
    --main-section-background-color: #0a0f17;
    --main-section-paragraph-color: #ffffff;
    --main-section-heading-color: hsl(220, 100%, 55%);
    --main-section-menu-block-btn-background: hsl(220, 100%, 55%);
    --main-section-menu-block-btn-color: #ffffff;
    --menu-position: relative;
}

header {
  background-color: var(--header-color);
  color: var(--header-text-color);
  position: var(--menu-position);
}
