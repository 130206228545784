@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.AdminDashBoard {
  width: 100%;
  background-color: rgba(213, 213, 213, 1);
  /* Default for desktop */
  .linkTag {
    text-decoration: none;
  }

  .slick-slider {
    height: 268px !important;
  }
}

@media (max-width: 599px) {
  /* For phones or smaller screens */
}

@media (max-width: 599px) {
  /* For phones or smaller screens */
}

.ImageFilter {
  width: 100px;  /* Default width */
  height: 100px; /* Default height */
}

@media (max-width: 768px) {
  .image-container {
    width: 40px;  /* Smaller size for tablets */
    height: 40px;
  }
}

@media (max-width: 480px) {
  .image-container {
    width: 40px;  /* Even smaller size for mobile devices */
    height: 400px;
  }
}
