@import "../Home/Home.scss";

.Header {
    a {
        text-decoration: none;
    }

    header {
        background: var(--header-color);
    }

    .searchbox {
        width: 40%;
    }

    .brand {
        img {
            width: 150px;
            height: 54px;
        }
    }

    .drawer-content {
        width: 100vw;
        height: 100vh;
        background: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 20px;
        box-sizing: border-box;
    }
    
    .close-btn {
        position: absolute !important;
        top: 15px;
        right: 20px;
        color: black;
    }
    

}