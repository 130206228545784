.Home {}

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap");
@import "./colors.scss";

*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
    list-style-type: none;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

@function text-color-based-on-bg($bg-color) {
    @if (lightness($bg-color) < 50%) {
        @return var(--colors-white-200); // Use white for dark backgrounds
    } @else {
        @return var(--colors-black-500); // Use black for light backgrounds
    }
}

html {
    font-size: 100%;
    box-sizing: inherit;
    scroll-behavior: smooth;
    height: -webkit-fill-available;
}

body {
    font-family: "Rubik", sans-serif;
    font-size: clamp(1rem, 2vw, 1.125rem);
    font-weight: 400;
    line-height: 1.5;
    height: -webkit-fill-available;
    color: var(--colors-white-100);
    background-color: var(--colors-black-500);
}


main {
    overflow: hidden;
}

a,
button {
    cursor: pointer;
    border: none;
    outline: none;
    user-select: none;
    background: none;
    box-shadow: none;
    text-decoration: none;
}

img,
video {
    display: block;
    max-width: 100%;
    height: auto;
    object-fit: cover;
}

// Element Section
.section {
    margin: 0 auto;
    padding: 6rem 0 1rem;
}

.container {
    max-width: 75rem;
    height: auto;
    margin: 0 auto;
    padding: 0 1.25rem;
}

.centered {
    text-align: center;
    vertical-align: middle;
    margin-bottom: 1rem;
}

.heading {
    &-xl {
        font-family: inherit;
        font-size: clamp(2.648rem, 6vw, 4.241rem);
        font-weight: 700;
        line-height: 1.15;
        letter-spacing: -1px;
    }

    &-lg {
        font-family: inherit;
        font-size: clamp(2.179rem, 5vw, 3.176rem);
        font-weight: 700;
        line-height: 1.15;
        letter-spacing: -1px;
    }

    &-md {
        font-family: inherit;
        font-size: clamp(1.794rem, 4vw, 2.379rem);
        font-weight: 700;
        line-height: 1.25;
        letter-spacing: -1px;
    }

    &-sm {
        font-family: inherit;
        font-size: clamp(1.476rem, 3vw, 1.782rem);
        font-weight: 600;
        line-height: 1.5;
    }

    &-xs {
        font-family: inherit;
        font-size: clamp(1.215rem, 2vw, 1.335rem);
        font-weight: 500;
        line-height: 1.5;
    }
}

.paragraph {
    font-family: inherit;
    font-size: clamp(1rem, 2vw, 1.125rem);
    text-wrap: balance;
    color: var(--main-section-paragraph-color); // Default white for dark backgrounds
}


.btn {
    display: inline-block;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    user-select: none;
    outline: none;
    border: none;
    border-radius: 0.25rem;
    text-transform: unset;
    transition: all 0.3s ease-in-out;

    &-inline {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        column-gap: 0.5rem;
    }

    &-darken {
        padding: 0.75rem 2rem;
        color: var(--colors-white-100);
        background-color: var(--colors-black-200);
        box-shadow: var(--shadows-medium);
    }

    &-neutral {
        padding: 0.75rem 2rem;
        color: var(--colors-black-500);
        background-color: var(--colors-white-100);
        box-shadow: var(--shadows-medium);
    }
}

// Header Section
.header {
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: auto;
    margin: 0 auto;
    transition: all 0.35s ease;

    &.on-scroll {
        background: var(--header-scroll-color);
        box-shadow: var(--shadows-medium);
    }

    @media screen and (max-width: 576px) {
        padding: 0px 15px;
    }
}

.navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 1.25rem;
    width: 100%;
    height: 4.25rem;
    margin: 0 auto;
}

.brand {
    font-family: inherit;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: -1px;
    color: var(--colors-white-100);
    text-transform: uppercase;
}

.menu {
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    height: auto;
    padding: 4rem 0 3rem;
    overflow: hidden;
    background-color: var(--colors-black-300);
    box-shadow: var(--shadows-medium);
    transition: all 0.4s ease-in-out;

    &.is-active {
        top: 0;
        width: 100%;
        height: auto;
    }

    &-inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 1.25rem;
    }

    &-link {
        font-family: inherit;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5;
        color: var(--header-text-color);
        text-transform: uppercase;
        transition: all 0.3s ease;
    }

    &-block {
        display: inline-block;
        font-family: inherit;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.25;
        user-select: none;
        white-space: nowrap;
        text-align: center;
        margin-left: auto;
        padding: 0.65rem 1.5rem;
        border-radius: 3rem;
        text-transform: capitalize;
        color: var(--colors-white);
        background-color: var(--colors-blue-600);
        box-shadow: var(--shadows-medium);
        transition: all 0.3s ease-in-out;
    }

    // Media Query Breakpoint
    @media only screen and (min-width: 48rem) {
        position: relative;
        top: 0;
        width: auto;
        height: auto;
        padding: 0rem;
        margin-left: auto;
        background: none;
        box-shadow: none;

        &-inner {
            display: flex;
            flex-direction: row;
            column-gap: 2rem;
            margin: 0 auto;
        }

        &-link {
            text-transform: capitalize;
        }

        &-block {
            margin-left: 2rem;
        }
    }
}

.burger {
    position: relative;
    display: block;
    cursor: pointer;
    user-select: none;
    order: -1;
    z-index: 10;
    width: 1.6rem;
    height: 1.15rem;
    border: none;
    outline: none;
    background: none;
    visibility: visible;
    transform: rotate(0deg);
    transition: 0.35s ease;

    // Media Query Breakpoint
    @media only screen and (min-width: 48rem) {
        display: none;
        visibility: hidden;
    }

    &-line {
        position: absolute;
        display: block;
        left: 0;
        width: 100%;
        height: 2px;
        border: none;
        outline: none;
        opacity: 1;
        border-radius: 1rem;
        transform: rotate(0deg);
        background-color: var(--colors-white-100);
        transition: 0.25s ease-in-out;

        &:nth-child(1) {
            top: 0px;
        }

        &:nth-child(2) {
            top: 0.5rem;
            width: 70%;
        }

        &:nth-child(3) {
            top: 1rem;
        }
    }

    &.is-active .burger-line {
        &:nth-child(1) {
            top: 0.5rem;
            transform: rotate(135deg);
        }

        &:nth-child(2) {
            opacity: 0;
            visibility: hidden;
        }

        &:nth-child(3) {
            top: 0.5rem;
            transform: rotate(-135deg);
        }
    }
}

// Banner Section
.banner {
    &-column {
        position: relative;
        display: grid;
        align-items: center;
        row-gap: 3rem;

        // Media Query Breakpoint
        @media only screen and (min-width: 48rem) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            justify-content: center;
        }

        @media only screen and (min-width: 64rem) {
            grid-template-columns: 1fr max-content;
            column-gap: 4rem;
            margin-top: 3rem;
        }
    }

    &-image {
        display: block;
        max-width: 18rem;
        height: auto;
        margin-top: 2rem;
        object-fit: cover;
        justify-self: center;

        // Media Query Breakpoint
        @media only screen and (min-width: 48rem) {
            order: 1;
            max-width: 20rem;
            height: auto;
        }

        @media only screen and (min-width: 64rem) {
            max-width: 25rem;
            height: auto;
            margin-right: 5rem;
        }
    }

    &-inner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 1.75rem;
    }

    &-links {
        position: absolute;
        top: 30%;
        right: 1.5rem;
        display: grid;
        justify-items: center;
        row-gap: 0.5rem;
        opacity: 0;
        visibility: hidden;

        // Media Query Breakpoint
        @media only screen and (min-width: 64rem) {
            opacity: 1;
            visibility: visible;
        }

        &>* {
            font-size: 1.25rem;
            line-height: 1.25;
            color: var(--main-section-paragraph-color);
        }

        &::before {
            position: absolute;
            content: "";
            top: -3rem;
            width: 4rem;
            height: 1.5px;
            transform: rotate(90deg);
            background: var(--main-section-paragraph-color);
        }

        &::after {
            position: absolute;
            content: "";
            bottom: -3rem;
            width: 4rem;
            height: 2px;
            transform: rotate(90deg);
            background: var(--main-section-paragraph-color);
        }
    }

    .heading-xl {
        color: var(--main-section-heading-color)
    }

    .banner-links {
        color: var(--colors-blue-100);
    }

    a {
        text-decoration: none;
    }
}

.menu-block {
    background: var(--main-section-menu-block-btn-background);
    color: var(--main-section-menu-block-btn-color);
    position: relative;
    z-index: 999; /* Make sure it's clickable */
}

.footer {
    z-index: 1;
    --footer-background: var(--colors-blue-700);
    display: grid;
    position: relative;
    grid-area: footer;
    min-height: 12rem;

    .bubbles {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1rem;
        background: var(--footer-background);
        filter: url("#blob");

        .bubble {
            position: absolute;
            left: var(--position, 50%);
            background: var(--footer-background);
            border-radius: 100%;
            animation: bubble-size var(--time, 4s) ease-in infinite var(--delay, 0s),
                bubble-move var(--time, 4s) ease-in infinite var(--delay, 0s);
            transform: translate(-50%, 100%);
        }
    }

    .content {
        z-index: 2;
        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: 4rem;
        padding: 2rem;
        background: var(--footer-background);

        a,
        p {
            color: #F5F7FA;
            text-decoration: none;
        }

        b {
            color: white;
        }

        p {
            margin: 0;
            font-size: .75rem;
        }

        >div {
            display: flex;
            flex-direction: column;
            justify-content: center;

            >div {
                margin: 0.25rem 0;

                >* {
                    margin-right: .5rem;
                }
            }

            .image {
                align-self: center;
                width: 4rem;
                height: 4rem;
                margin: 0.25rem 0;
                background-size: cover;
                background-position: center;
            }
        }
    }
}

@keyframes bubble-size {

    0%,
    75% {
        width: var(--size, 4rem);
        height: var(--size, 4rem);
    }

    100% {
        width: 0rem;
        height: 0rem;
    }
}

@keyframes bubble-move {
    0% {
        bottom: -4rem;
    }

    100% {
        bottom: var(--distance, 10rem);
    }
}