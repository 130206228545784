@import "../Home/Home.scss";

.HeaderDark1 {
    a {
        text-decoration: none;
    }

    header {
        background: var(--colors-black-700);
    }

    .searchbox {
        width: 40%;
    }
}