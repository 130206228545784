@import "../Home/Home.scss";

.StickeyFullMenu {
    a {
        text-decoration: none;
    }

    header {
        background: var(--header-color);
    }

    .searchbox {
        width: 40%;
    }

    .brand {
        img {
            width: 150px;
            height: 54px;
        }
    }
}