@import "../Home/Home.scss";

.SearchBox {
    @import url('https://fonts.googleapis.com/css?family=Raleway:400,700,900');
    position: sticky;
    top: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    justify-self: center;
    background: var(--header-search-color);
    /* Base styling */
    z-index: 1000;
    .search {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        &__container {
            padding-top: 64px;
        }

        &__title {
            font-size: 22px;
            font-weight: 900;
            text-align: center;
            color: #ff8b88;
        }

        &__input {
            width: 100%;
            padding: 12px 24px;
            font-size: 14px;
            line-height: 18px;
            color: #575756;
            background-color: #ffffff;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: 18px 18px;
            background-position: 95% center;
            border-radius: 50px;
            border: 1px solid #575756;
            transition: all 250ms ease-in-out;
            backface-visibility: hidden;
            transform-style: preserve-3d;

            &::placeholder {
                color: rgba(87, 87, 86, 0.8);
                text-transform: uppercase;
                letter-spacing: 1.5px;
            }

        }
    }

    .credits {
        &__container {
            margin-top: 24px;
        }

        &__text {
            text-align: center;
            font-size: 13px;
            line-height: 18px;
        }

        &__link {
            color: #ff8b88;
            text-decoration: none;
            transition: color 250ms ease-in;

            &:hover,
            &:focus {
                color: darken(#ff8b88, 25%);
            }
        }
    }

}