@import "../Home/Home.scss";

.MainSection {
    width: 100%;
    background: var(--main-section-background-color);
}

.main {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @media (min-width: 768px) {
        height: 74vH;
    }
}